<template>
  <div>
    <!-- Site content -->
    <div
      class="bg-neutral-100 text-neutral-800 dark:bg-black dark:text-neutral-200"
      id="mainContainer"
    >
      <main>
        <slot />
      </main>
    </div>
  </div>
</template>

<script setup lang="ts"></script>
